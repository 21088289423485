export default {
  "header": {
    "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt olmak"])},
    "SL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sri Lanka"])},
    "China": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çin"])},
    "Laos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laos"])},
    "Turkiye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Türkiye"])}
  },
  "footer": {
    "Services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hizmetler"])},
    "Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şirket"])},
    "Social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sosyal"])},
    "ICanMeta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICan Cüzdanı"])},
    "ICanChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sohbet Edebilirim"])},
    "Properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICan Özellikleri"])},
    "ServiceAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hizmet sözleşmesi"])},
    "PrivacyAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gizlilik sözleşmesi"])},
    "QRPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İade ve İade"])},
    "Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politikalar"])},
    "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yardım desteği"])},
    "AboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hakkımızda"])},
    "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bize Ulaşın"])},
    "SocilaMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlı kalın"])},
    "SaveApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulamayı indirin"])}
  },
  "message": {
    "LoginSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı oturum açma başarısı"])},
    "LoginToMall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iCan Mall'a giriş yapın"])},
    "SomethingWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir şeyler yanlış gitti"])},
    "DataDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süresi dolmuş kontrol edin!"])},
    "AddressAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres başarıyla eklendi"])},
    "DeleteAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresi başarıyla sil"])},
    "UpdateAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres başarıyla değiştirildi"])},
    "ChangeAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönderim adresi değiştirildi"])},
    "CodeSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod gönderildi"])},
    "ResetSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarıyı sıfırla"])},
    "AddFavourits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorilere eklendi"])},
    "ItemOutOfStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stokta yok"])},
    "AddToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sepete eklendi"])},
    "LoggedOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çıkış yapıldı"])},
    "PasswordUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre başarıyla güncellendi!"])},
    "ProfileUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil başarıyla güncellendi!"])},
    "OrderCancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sipariş iptal edildi"])},
    "InvalidNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geçersiz telefon numarası"])},
    "RegisterSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt başarılı"])},
    "NoFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtresiz"])},
    "FreeDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bölgenizde ücretsiz teslimat"])},
    "Reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üzgünüz, burada hiç yorum yok!"])},
    "prodnotAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu ürün bölgenizde mevcut değil"])},
    "AddAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lütfen adresinizi ekleyin"])},
    "DelOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siparişi başarıyla sil"])},
    "HaveAcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaten hesabınız var mı?"])},
    "CODPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teslimat adresinize ürün teslim edildiğinde kuryemize nakit veya banka kartıyla ödeme yapabilirsiniz."])},
    "cancelledOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sipariş başarıyla iptal edildi"])},
    "onlyfiveImgs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yalnızca beş resim yükleyebilirsiniz"])},
    "EnterYourThought": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lütfen düşüncelerinizi girin"])},
    "CommentsAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorumlarınız başarıyla eklendi"])},
    "NoCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu Kategoride Gösterilecek Bir Şey Yok!"])},
    "ConfirmByCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödemeyi onayladıktan sonra seçilen kartla devam edilecektir"])},
    "NoVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu kategoride gösterilecek bir şey yok!"])},
    "Success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarı"])},
    "FormValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata! Birkaç alanı kaçırmışsınız veya geçersiz veri girmişsiniz gibi görünüyor."])},
    "PageNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 - Page Not Found"])},
    "PageNotFoundDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, the page you're looking for cannot be found."])},
    "GoHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Home"])},
    "minPayAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" The payment amount shall not be less than"])},
    "alreadyDefaultAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected address is already your default address"])}
  },
  "headerTitle": {
    "ICanMall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iCanMall"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabı kaydet"])},
    "ChangePwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifreyi yenile"])},
    "goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mal"])},
    "IcanServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iCan Hizmetleri"])},
    "TrendingDeals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trend Olan Fırsatlar"])},
    "YouMayLike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beğenebilirsin"])},
    "MyCollection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favoriler"])},
    "Myfootprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ayak izim"])},
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "DefaultAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varsayılan adresi ekle"])},
    "Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayarlar"])},
    "AccountSecurity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap Güvenliği"])},
    "PersonalProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kişisel profil"])},
    "PasswordSecurity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre güvenliği"])},
    "EditProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kişisel profili düzenle"])},
    "PwdChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre değiştir"])},
    "ProdDiscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürün Açıklaması"])},
    "ProdSpecifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürün Özellikleri"])},
    "CusReviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musteri degerlendirmeleri"])},
    "AbtSeller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satıcı hakkında"])},
    "SimilarProd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benzer ürünler"])},
    "AllReviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm incelemeler"])},
    "DeliveryAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teslimat adresi"])},
    "OrderSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sipariş özeti"])},
    "TotAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam tutar"])},
    "PaymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme metodları"])},
    "Summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özet"])},
    "Addr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresler"])},
    "EditAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresi düzelt"])},
    "AddNewAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yeni adres ekleyin"])},
    "ShoppingCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alışveriş sepetiniz"])},
    "CartEmplty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sepetiniz boş"])},
    "Recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sizin için önerilen"])},
    "ToPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödemek"])},
    "ToDeliver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teslim etmek"])},
    "ToReceive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almak"])},
    "Completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamamlanmış"])},
    "Refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri ödemek"])},
    "Speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzmanlık"])},
    "History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarih"])},
    "Popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popüler"])},
    "searchResultFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İçin arama sonuçları"])},
    "searchResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arama Sonuçları"])},
    "Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre"])},
    "Products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürünler"])},
    "Shops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mağazalar"])},
    "CoinPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madeni para fiyatı"])},
    "CoinRrecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madeni para kayıtları"])},
    "Messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildiri"])},
    "Invitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Davetim"])},
    "Categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriler"])},
    "Brands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markalar"])},
    "COD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COD ile onaylansın mı?"])},
    "OrderPlaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sipariş verildi"])},
    "CourierService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurye servisi"])},
    "TrackingNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takip numarası"])},
    "OrederCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siparişi iptal et"])},
    "RateProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir Ürünü Değerlendirin"])},
    "Notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildirimler"])},
    "Download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İndirmek"])},
    "Mall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iCan Lanka PVT Ltd"])},
    "App": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulama"])},
    "ConfirmReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifi onaylıyor musunuz"])},
    "Vouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuponlarım"])},
    "Trending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bugünün En Trend Fırsatları"])},
    "AppAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iCan alışveriş merkezi uygulaması şu anda mevcut"])},
    "confirmedOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onayla ve Öde"])},
    "SelectPaymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ödeme türünü seçin"])},
    "SaveCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıtlı kartlar"])}
  },
  "subTitles": {
    "DefaultAddrSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen daha fazla bilgi sağlayın"])},
    "IcanMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet ve öde Kart bilgilerimin sonraki işlemler için Banka kartı kasasında saklanacağını kabul ediyorum. Gerekirse kartı \\ konumunda çıkarabilirsiniz.\"Payment Options\" section in the \"Account\" menu."])},
    "LoginEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta ile giriş yapın"])},
    "LoginMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobil ile Giriş Yapın"])},
    "Forgotpwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parolanızı mı unuttunuz?"])},
    "CreateAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabınızı oluşturmak için bilgilerinizi girin"])},
    "RegisterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta ile kayıt olun"])},
    "RegisterMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobil cihazla kaydolun"])},
    "PleaseEnterMobNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen kayıtlı cep telefonu numaranızı giriniz"])},
    "PleaceEnterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen kayıtlı e-posta adresinizi girin"])},
    "ResetWithMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobil cihazla sıfırla"])},
    "ResetWithEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postayla sıfırla"])},
    "AreYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emin misin?"])},
    "Item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unid"])},
    "Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro"])},
    "Additional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ek açıklama ve kimlik bilgileri"])},
    "CancelledDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal Ayrıntıları"])},
    "RateProd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürününüzü derecelendirin"])}
  },
  "placeholeds": {
    "Register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt olmak"])},
    "ShopNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şimdi satın al"])},
    "Change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değiştirmek"])},
    "AddNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni ekle"])},
    "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düzenlemek"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal etmek"])},
    "SaveConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet ve Onayla"])},
    "PlaceOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sipariş Vermek"])},
    "SeeAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hepsini gör"])},
    "GetCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kodu Al"])},
    "ResetPwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifreyi yenile"])},
    "AddCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sepete ekle"])},
    "BuyNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şimdi al"])},
    "WriteReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir değerlendirme yazın"])},
    "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HAYIR"])},
    "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evet"])},
    "Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hizmet"])},
    "Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mahremiyet"])},
    "Reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sıfırla"])},
    "SaveCanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değişiklikleri Kaydet"])},
    "Checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çıkış yapmak"])},
    "SelectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hepsini seç"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silmek"])},
    "DeleteAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hepsini sil"])},
    "ContinueShopping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alışverişe devam"])},
    "SignOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturumu Kapat"])},
    "AboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hakkımızda"])},
    "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil"])},
    "ServiceAgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hizmet sözleşmesi"])},
    "PrivacyAgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gizlilik Sözleşmesi"])},
    "ContactSeller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satıcıyla iletişime geç"])},
    "ViewLogistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lojistiği Görüntüle"])},
    "ConfirmReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makbuzu Onayla"])},
    "DeleteOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siparişi Sil"])},
    "Evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürünü değerlendirin"])},
    "CancelOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siparişi iptal et"])},
    "Payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme"])},
    "ContactName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kişi adı"])},
    "Country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ülke"])},
    "Province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vilayet"])},
    "District": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semt"])},
    "Town": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şehir"])},
    "AddrL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres satırı"])},
    "Zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posta kodu"])},
    "SearchProd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürünleri ara"])},
    "VisitStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mağazayı ziyaret et"])},
    "ConfirmOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onaylamak"])},
    "ViewOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siparişi görüntüle"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Göndermek"])},
    "SubmitReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum Gönder"])},
    "AccoutAndSecutiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap Güvenliği"])},
    "Collect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOPLAMAK"])},
    "DownloadApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iCan alışveriş merkezi uygulamasını indirin"])},
    "confirmOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sipariş onaylamak"])},
    "continuePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödemek"])},
    "Loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yükleniyor..."])},
    "LoadMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha fazla yükle"])},
    "PasswdLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre girişi"])},
    "QuickLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hızlı Giriş"])},
    "ShippinFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ücretsiz kargo"])},
    "Default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По умолчанию"])}
  },
  "formLabels": {
    "PersonalInfor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kişisel bilgi"])},
    "CountryRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ülke/Bölge"])},
    "Adres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
    "MobileNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cep numarası"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta"])},
    "Passwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre"])},
    "NewPasswd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Şifre"])},
    "ConfirmPasswd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifreyi Onayla"])},
    "VerificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğrulama kodu"])},
    "DefaultAddrlabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varsayılan adres olarak ayarla"])},
    "Nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takma ad"])},
    "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
    "Gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cinsiyet"])},
    "BD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğum günü"])},
    "Currentpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut Şifre"])},
    "InviteCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Davet Kodu"])},
    "IAgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katılıyorum"])},
    "Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal Edilen Tutar"])},
    "CancelReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal nedenini seçin"])},
    "AddRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düşüncelerinizi ekleyin"])},
    "AddImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görsel ekle"])},
    "isAnonymus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir ürünü Anonim olarak değerlendirin"])},
    "Clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temizlemek"])},
    "MobileOrPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cep telefonu numarası veya E-posta"])}
  },
  "Label": {
    "Sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SATILMIŞ"])},
    "Vouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuponlar"])},
    "Category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
    "DeliveryDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahmini teslim tarihi"])},
    "Quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miktar"])},
    "5Star": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 yıldız"])},
    "4Star": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4Yıldız"])},
    "3Star": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3Yıldız"])},
    "2Star": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2Yıldız"])},
    "1Star": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1Yıldız"])},
    "Subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ara toplam"])},
    "BasketCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sepetteki sepetler"])},
    "ItemCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürün Kodu"])},
    "TotalItemCost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam ürün maliyeti"])},
    "SavedVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuponlarla kaydedildi"])},
    "PromoCodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promosyon kodları"])},
    "Shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teslimat ücreti"])},
    "Toplam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam"])},
    "SaveCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kart Detaylarını Kaydet"])},
    "Free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özgür"])},
    "Buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satın almak"])},
    "OrderNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sipariş numarası"])},
    "RefundAmmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İade Tutarı"])},
    "Cod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapıda ödeme"])},
    "InStok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stokta var"])},
    "OutOfStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stoklar tükendi"])},
    "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beni Tara"])},
    "AppDisriptionFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şaşırtıcı teknolojilerle e-ticarette bir sonraki devrim"])},
    "AppDisriptionSecond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["teklifler ve inanılmaz fiyatlar"])},
    "CopyRight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her hakkı saklıdır."])},
    "DefaultAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varsayılan teslimat adresi"])},
    "NoAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres belirtilmedi"])},
    "VocherCampain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ican alışveriş merkezine hoş geldiniz! Özel Yeni Kullanıcı Kuponlarınızı Şimdi Alın!🎉"])},
    "DownloadDiscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muhteşem teklifler ve inanılmaz fiyatlarla e-ticarette bir sonraki devrim."])},
    "UseAppDiscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öğeleri bulmak, tedarikçilerle iletişim kurmak, siparişlerinizi yönetmek ve ödeme yapmak için iCan alışveriş merkezi uygulamasını istediğiniz zaman, istediğiniz yerde kullanın."])},
    "GetApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulamayı al"])},
    "subTot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ara toplam"])},
    "ShipFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nakliye"])},
    "Cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredi / Banka Kartı"])},
    "Deleverytime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teslimat süresi"])},
    "DeliveryService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teslimat hizmeti"])},
    "EstimatedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahmini Teslim Tarihi"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onaylamak"])},
    "TimeSlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaman aralığı"])},
    "Delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teslimatı planlayın"])},
    "Availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Availability"])},
    "Ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratings"])},
    "Courier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courier"])},
    "FreeShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Shipping"])},
    "DeliveryTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery To"])},
    "DeliveryDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Date"])},
    "EstimateDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimate delivery"])},
    "NA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
    "Warranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warranty"])},
    "YearWarranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month Seller Warranty"])},
    "WarrantyNotAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warranty Not Available"])},
    "ShipFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ship From"])},
    "Overseas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overseas"])},
    "Voucher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vouchers"])},
    "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
    "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
    "VisitStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit store"])},
    "AddToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add To Cart"])},
    "BuyNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy Now"])},
    "ProductSpecifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Specifications"])},
    "ChatNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat Now"])},
    "SoldOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SOLD OUT"])}
  },
  "ValidationMsg": {
    "FieldRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu alan gereklidir"])},
    "SelectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen ülke kodunu seçin"])},
    "ValidNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen geçerli bir Cep telefonu numarası girin"])},
    "EnterPw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen şifre giriniz"])},
    "PWRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre başta veya sonda boşluk içeremez"])},
    "OTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen doğrulama kodunu girin"])},
    "ValiedCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen geçerli kodu girin"])},
    "Passwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen şifreyi tekrar girin"])},
    "PwNotMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre eşleşmiyor"])},
    "EnterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen e-posta giriniz"])},
    "ValidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen geçerli bir e-posta girin"])},
    "MobNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen cep telefonu numaranızı girin"])},
    "ValidNickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen takma adı girin"])},
    "PwTips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre 8 karakter uzunluğunda olmalı ve en az bir Büyük harf, Basit harf, Sembol ve sayı içermelidir."])},
    "AddrTips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karakter sayısı 500'ü geçemez"])}
  },
  "NotItemsMessage": {
    "notItemcart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sepetinizde ürün yok"])},
    "notItemTopay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödenecek öğe yok"])},
    "notNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herhangi bir bildirim yok"])},
    "notItemToDeliver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teslim edilecek ürün yok"])},
    "notSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üzgünüm. Arama teriminiz için herhangi bir eşleşme bulamıyoruz"])},
    "notFavourit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalp simgesine tıklayarak favorilerinize bir öğe ekleyebilirsiniz."])},
    "notItemToReceive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alınacak herhangi bir öğe yok"])},
    "notCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamamlandı bölümünde hiçbir öğe yok"])},
    "notFootprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayak izi boş!"])},
    "notItemRefund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İade edilecek ürün yok"])},
    "notReviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üzgünüz bu ürün için henüz yorum yapılmamış"])}
  },
  "CancelOrderReasons": {
    "Duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekrarlanan sipariş"])},
    "WrongProd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanlış ürün açıklaması"])},
    "ChangeAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teslimat adresi değiştirildi"])},
    "ChangeMind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fikrimi değiştirdim"])},
    "FoundCheaper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başka yerde daha ucuz bulundu"])},
    "deliveryTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teslim süresi çok uzun"])}
  },
  "invitationInfo": {
    "UnlockRewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "ScantoRefer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir Arkadaşınıza Tavsiye Etmek İçin Tarayın!🎉"])},
    "MyInvitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Davetim"])},
    "Invited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Davet edildi"])},
    "YourRank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rütbeniz"])},
    "confInvitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onaylanmış Davetiye"])},
    "Leaderboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liderler Sıralaması"])},
    "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rütbe"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsim"])},
    "Refferel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referans"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yapılandırma"])}
  },
  "voucherInfo": {
    "CappedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sınırlı"])},
    "PercentageDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["% KAPALI"])},
    "FreeShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "VoucherValidDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "VoucherApply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygula"])},
    "VoucherApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulamalı"])},
    "VoucherCollect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOPLAMAK"])},
    "VoucherCollected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplanmış"])},
    "VoucherCollectedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "VoucherCollectError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "AllVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm"])},
    "ProductVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürün"])},
    "ShopVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mağaza"])},
    "MallVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iCan Alışveriş Merkezi"])},
    "MyVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "RecommendedVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tavsiye edilen"])},
    "ViewAllVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha"])},
    "ShopVoucherTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kupon ve İndirim"])}
  },
  "campain": {
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Günler"])},
    "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saat"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dakika"])},
    "sec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saniye"])}
  },
  "GroupBuy": {
    "groupBuy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup satın alma"])},
    "NeedParticipant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ihtiyaç"])},
    "NoParticipant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "timeOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zaman aşımı"])},
    "Invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gün"])},
    "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["saat"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dk."])},
    "sec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["saniye"])},
    "InPeogress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devam etmekte"])},
    "Upcomming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yaklaşan"])},
    "Completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamamlanmış"])}
  },
  "AddressMobile": {
    "alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternatif"])},
    "enterAlt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternatif numarayı girin"])},
    "area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alan"])},
    "selectArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İl, ilçe, şehir seçiniz"])},
    "yourArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bölgenizi seçin"])},
    "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seçme"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapalı"])},
    "Country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["country"])},
    "Province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["province"])},
    "District": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["district"])},
    "Town": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["town"])}
  },
  "Languages": {
    "English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İngilizce"])},
    "Chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çince"])},
    "Turkish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Türkçe"])},
    "Russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rusça"])},
    "Portugal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portekiz"])}
  },
  "BannerText": {
    "BannerBottomHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlock Your Shopping Adventure!"])},
    "BannerBottomSub1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download our app now to discover electronics, fashion experiences, amazing offers, and more!"])},
    "BannerBottomSub2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan the Qr code to download iCan Mall"])}
  },
  "OrderDetails": {
    "OrderDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order details"])},
    "CourierService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courier service"])},
    "TrackingNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracking number"])},
    "Receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipt"])},
    "OrderNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order number"])},
    "Copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
    "PlacedOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placed on"])},
    "PaidOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid on"])},
    "Remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarks"])},
    "Insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery insurance"])},
    "ShopDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop discount"])},
    "SeeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See more"])},
    "SeeLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See less"])},
    "SubmitOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit order"])},
    "Paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
    "Shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipped"])},
    "Delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivered"])},
    "Copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["copied"])}
  },
  "ContactUs": {
    "ContactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "SubHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real time and fast responses"])},
    "NeedHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need help?"])},
    "ContactEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can contact iCan mall Customer service for help."])},
    "OfficeAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Office Address"])},
    "SocialMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are also available on social media"])}
  },
  "wishList": {
    "GroceryList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bakkal Listesi"])},
    "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seçme"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silmek"])},
    "CreateList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alışveriş listesi oluştur"])},
    "TotalPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam Tutar"])},
    "NothingGrocery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alışveriş listeniz yok"])},
    "NothingGroceryMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alışveriş listenizi oluşturun"])},
    "CreateNewBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Liste Oluştur"])},
    "GrocryListLopic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni liste"])},
    "CreateListValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste adı boş olamaz"])},
    "CreateListBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste oluştur"])},
    "SelectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümünü seç"])},
    "AddItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öğe ekle"])},
    "ItemListEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listeniz boş"])},
    "ItemEmptyMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alışveriş listenize ürün ekleyerek başlayın."])},
    "AddingItemBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öğe ekle"])},
    "Discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İndirimli Fiyat"])},
    "SubTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ara toplam"])},
    "Delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teslimat ücreti"])},
    "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam fiyat"])},
    "ShareList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listeyi Paylaş"])},
    "BuyList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satın Alma Listesi"])},
    "CreateGrecerySuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created grocery list successfuly"])},
    "FaildeGroceryError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to create grocery list"])},
    "DeleteItemssuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Item Successfully"])},
    "SelectedWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the items"])},
    "Quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miktar"])},
    "Confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yapılandırma"])},
    "ItemAddedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item added successfuly"])},
    "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items"])},
    "Add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "More": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["more to save"])},
    "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save "])},
    "PlaceholederTextBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Grocery List Name"])}
  }
}
<template>
  <div class="app-container" id="app">
    <!-- falling effect -->
    <div id="snow-container"></div>
    <login />
    <download-app />
    <router-view />
  </div>
</template>

<script>
import Login from "./views/Login/Login.vue";
import DownloadApp from "./components/downloadApp/DownloadApp.vue";

export default {
  components: {
    Login,
    DownloadApp,
  },
  mounted() {
    //this.startSnowEffect();
  },
  methods: {
    // startSnowEffect() {
    //   const snowContainer = document.getElementById("snow-container");
    //   let snowflakeCount = 0;

    //   const createSnowflake = () => {
    //     if (snowflakeCount >= 75) return;

    //     const snowflake = document.createElement("div");
    //     snowflake.className = "snowflake";
    //     // falling character
    //     snowflake.innerHTML = "&#10052;";

    //     const startX = Math.random() * 100;
    //     const angle = (Math.random() * 2 - 1) * 60;

    //     snowflake.style.left = `${startX}vw`;
    //     snowflake.style.fontSize = `${Math.random() * 10 + 10}px`;

    //     const duration = Math.random() * 10 + 20;
    //     snowflake.style.animationDuration = `${duration}s`;

    //     snowflake.style.transform = `translateX(${angle}vw)`;

    //     snowContainer.appendChild(snowflake);
    //     snowflakeCount++;

    //     snowflake.addEventListener("animationend", () => {
    //       snowflake.remove();
    //       snowflakeCount--;
    //     });
    //   };

    //   setInterval(createSnowflake, 2000);
    // },
  },
};
</script>

<style lang="sass">
.app-container
  position: relative
  overflow: hidden
  //background-color: #ECEAEA

#snow-container
  position: fixed  
  top: 0
  left: 0
  width: 100%
  height: 100%
  pointer-events: none
  z-index: 999 

.snowflake
  position: absolute
  top: -10px
  color: white
  opacity: 0.8
  animation: fall linear infinite
  pointer-events: none
  z-index: 999
  //text-shadow: 0 0 5px #2174FA, 0 0 10px #2174FA, 0 0 15px #2174FA

@keyframes fall
  to
    transform: translateY(100vh) translateX(0) 
</style>

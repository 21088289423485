export default {
  "header": {
    "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
    "SL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["斯里兰卡"])},
    "China": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中国"])},
    "Laos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["老挝"])},
    "Turkiye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["土耳其"])}
  },
  "footer": {
    "Services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务"])},
    "Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司"])},
    "Social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICan APP"])},
    "ICanMeta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICan APP"])},
    "ICanChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICan Chat"])},
    "Properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICan Properties"])},
    "ServiceAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务协议"])},
    "PrivacyAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐私协议"])},
    "QRPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退款与退货条款"])},
    "Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Policies"])},
    "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help & Support"])},
    "AboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
    "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "SocilaMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关注iCanMall"])},
    "SaveApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载 app"])}
  },
  "message": {
    "LoginSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册成功"])},
    "LoginToMall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录 iCan Mall"])},
    "SomethingWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出了些问题"])},
    "DataDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请重新下单！"])},
    "AddressAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存成功"])},
    "DeleteAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除成功"])},
    "UpdateAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改成功"])},
    "ChangeAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收货地址修改成功"])},
    "CodeSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证码已发送"])},
    "ResetSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置成功"])},
    "AddFavourits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收藏成功"])},
    "ItemOutOfStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["却货"])},
    "AddToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功"])},
    "LoggedOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登出成功"])},
    "PasswordUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码更新成功！"])},
    "ProfileUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资料更新成功！"])},
    "OrderCancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已取消"])},
    "InvalidNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手机号无效"])},
    "RegisterSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册成功"])},
    "NoFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无筛选"])},
    "FreeDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本地区免费送货"])},
    "Reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待评价."])},
    "prodnotAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此产品不在该地区销售"])},
    "AddAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加地址"])},
    "DelOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删掉成功"])},
    "HaveAcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已经有账号？"])},
    "CODPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当您在收货地址收到货物时，您可以用现金或银行卡支付给我们的快递员。"])},
    "cancelledOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 取消成功"])},
    "onlyfiveImgs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最多上传5张图片 "])},
    "EnterYourThought": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评价宝贝"])},
    "CommentsAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评价成功"])},
    "NoCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["货卖光了哦"])},
    "ConfirmByCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认银行卡支付?"])},
    "NoVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此类别中没有可显示的内容！"])},
    "Success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发布成功"])},
    "FormValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["哎呀！ 您似乎遗漏了一些字段或输入了无效数据。"])},
    "PageNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 - Page Not Found"])},
    "PageNotFoundDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, the page you're looking for cannot be found."])},
    "GoHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Home"])},
    "minPayAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付金额不得少于"])},
    "alreadyDefaultAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所选地址已经是您的默认地址"])}
  },
  "headerTitle": {
    "ICanMall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iCanMall"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册账户"])},
    "ChangePwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重设密码"])},
    "goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宝贝"])},
    "IcanServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iCan 服务"])},
    "TrendingDeals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["趋势交易"])},
    "YouMayLike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["猜你喜欢"])},
    "MyCollection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的收藏"])},
    "Myfootprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的足迹"])},
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "DefaultAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加默认地址"])},
    "Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设置"])},
    "AccountSecurity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账号与安全"])},
    "PersonalProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人资料"])},
    "PasswordSecurity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码与安全"])},
    "EditProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑个人资料"])},
    "PwdChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改密码"])},
    "ProdDiscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品描述"])},
    "ProdSpecifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品规格"])},
    "CusReviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
    "AbtSeller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于卖方"])},
    "SimilarProd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类似产品"])},
    "AllReviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有评论"])},
    "DeliveryAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收货地址"])},
    "OrderSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单摘要"])},
    "TotAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总金额"])},
    "PaymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付方式"])},
    "Summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["概括"])},
    "Addr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
    "EditAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑地址"])},
    "AddNewAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加新地址"])},
    "ShoppingCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购物车"])},
    "CartEmplty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购物车空空如也"])},
    "Recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["猜你喜欢"])},
    "ToPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待付款"])},
    "ToDeliver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待发货"])},
    "ToReceive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待收货"])},
    "Completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已完成"])},
    "Refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退款/售后"])},
    "Speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专业"])},
    "History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索历史"])},
    "Popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受欢迎的"])},
    "searchResultFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为搜索结果"])},
    "searchResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索结果"])},
    "Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["筛选"])},
    "Products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品"])},
    "Shops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商店"])},
    "CoinPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["硬币价格"])},
    "CoinRrecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["硬币记录"])},
    "Messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["消息"])},
    "Invitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的邀请"])},
    "Categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分类"])},
    "Brands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品牌"])},
    "COD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认选择货到付款?"])},
    "OrderPlaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已下订单"])},
    "CourierService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["快递公司"])},
    "TrackingNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["快递单号"])},
    "OrederCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消订单"])},
    "RateProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评价宝贝"])},
    "Notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提示"])},
    "Download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载"])},
    "Mall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iCanMall"])},
    "App": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App"])},
    "ConfirmReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认收货"])},
    "Vouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的优惠券"])},
    "Trending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["热门"])},
    "AppAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载iCanMall App"])},
    "confirmedOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认"])},
    "SelectPaymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付方式"])},
    "SaveCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["常用卡"])}
  },
  "subTitles": {
    "DefaultAddrSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请提供更多信息"])},
    "IcanMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存并支付 ：我同意银行卡信息保存在银行。您需要删除保存的卡到我的》支付方式可以删掉。"])},
    "LoginEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮箱登录"])},
    "LoginMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手机登录"])},
    "Forgotpwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忘记密码？"])},
    "CreateAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入信息以创建您的帐户"])},
    "RegisterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮箱注册"])},
    "RegisterMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手机注册"])},
    "PleaseEnterMobNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入注册的手机号码"])},
    "PleaceEnterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入电子邮箱"])},
    "ResetWithMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手机重置密码"])},
    "ResetWithEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮箱重置密码"])},
    "AreYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你确定吗？"])},
    "Item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宝贝"])},
    "Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["筛选"])},
    "Additional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["补充描述和凭证"])},
    "CancelledDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 取消订单"])},
    "RateProd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评价宝贝"])}
  },
  "placeholeds": {
    "Register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登记"])},
    "ShopNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["去逛逛"])},
    "Change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择"])},
    "AddNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加新的"])},
    "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
    "SaveConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存并确认"])},
    "PlaceOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下单"])},
    "SeeAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
    "GetCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获取验证码"])},
    "ResetPwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重设密码"])},
    "AddCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添入购物车"])},
    "BuyNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即购买"])},
    "WriteReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["写评论"])},
    "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不"])},
    "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是"])},
    "Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务协议"])},
    "Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐私协议"])},
    "Reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置"])},
    "SaveCanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
    "Checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交订单"])},
    "SelectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全选"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])},
    "DeleteAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除所有"])},
    "ContinueShopping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续购物"])},
    "SignOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登出"])},
    "AboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于我们"])},
    "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["语言"])},
    "ServiceAgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务协议"])},
    "PrivacyAgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐私协议"])},
    "ContactSeller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系卖家"])},
    "ViewLogistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看物流"])},
    "ConfirmReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认收货"])},
    "DeleteOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除订单"])},
    "Evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评价"])},
    "CancelOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消订单"])},
    "Payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付"])},
    "ContactName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系人姓名"])},
    "Country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国家"])},
    "Province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["省"])},
    "District": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["区"])},
    "Town": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["镇"])},
    "AddrL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
    "Zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮政编码"])},
    "SearchProd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索"])},
    "VisitStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进店逛逛"])},
    "ConfirmOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认订单"])},
    "ViewOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看订单"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交"])},
    "SubmitReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交评价"])},
    "AccoutAndSecutiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户与安全"])},
    "Collect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["领取"])},
    "DownloadApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载iCanMall App"])},
    "confirmOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认订单"])},
    "continuePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付"])},
    "Loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 加载中..."])},
    "LoadMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 更多产品"])},
    "PasswdLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 密码登录"])},
    "QuickLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["快捷登录"])},
    "ShippinFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包郵"])},
    "Default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["默认"])}
  },
  "formLabels": {
    "PersonalInfor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人信息"])},
    "CountryRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国家/地区"])},
    "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
    "MobileNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手机号码"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
    "Passwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
    "NewPasswd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新密码"])},
    "ConfirmPasswd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认密码"])},
    "VerificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证码"])},
    "DefaultAddrlabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设置为默认地址"])},
    "Nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["昵称"])},
    "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话"])},
    "Gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["性别"])},
    "BD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生日"])},
    "Currentpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前密码"])},
    "InviteCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请码"])},
    "IAgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同意"])},
    "Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 取消金额"])},
    "CancelReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消原因"])},
    "AddRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评价宝贝可以帮助更多人哦"])},
    "AddImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传图片"])},
    "isAnonymus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传图片"])},
    "Clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清理"])},
    "MobileOrPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入您的邮箱/手机号码"])}
  },
  "Label": {
    "Sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["销量"])},
    "Vouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["优惠券"])},
    "Category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分类"])},
    "DeliveryDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预计到达日期"])},
    "Quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量"])},
    "5Star": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5星"])},
    "4Star": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4星"])},
    "3Star": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3星"])},
    "2Star": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2星"])},
    "1Star": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1星"])},
    "Subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小计"])},
    "BasketCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购物车中的篮子"])},
    "ItemCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["项目代码"])},
    "TotalItemCost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总项目成本"])},
    "SavedVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["券后"])},
    "PromoCodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["促销代码"])},
    "Shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运费"])},
    "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
    "SaveCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存卡详细信息"])},
    "Free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包邮"])},
    "Buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即购买"])},
    "OrderNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单号"])},
    "RefundAmmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退款金额"])},
    "Cod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["货到付款"])},
    "InStok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有库存"])},
    "OutOfStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["库存不足"])},
    "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan me"])},
    "AppDisriptionFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The next revolution in e-commerce with amazing"])},
    "AppDisriptionSecond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["offers,and unbelievable prices "])},
    "CopyRight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["版权所有iCan Group."])},
    "DefaultAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["默认地址"])},
    "NoAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["填写收货地址"])},
    "VocherCampain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欢迎使用iCanMall！马上领取您专属的新用户优惠券！🎉"])},
    "DownloadDiscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大家在里面购买商品,不仅优惠,而且优质,并且配送速度快！"])},
    "UseAppDiscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["精选电商优质自有品牌，全场自营商品，甄选全球好物让购物更简单！通过“社交+电商”的模式,让更多的用户带着乐趣分享实惠."])},
    "GetApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载iCanMall App"])},
    "subTot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小计"])},
    "ShipFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["快递费"])},
    "Cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["银行卡"])},
    "Deleverytime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到货时间"])},
    "DeliveryService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["快递"])},
    "EstimatedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预计到达日期"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已购买"])},
    "TimeSlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["时间"])},
    "Delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收货地址"])},
    "Availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Availability"])},
    "Ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratings"])},
    "Courier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courier"])},
    "FreeShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Shipping"])},
    "DeliveryTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery To"])},
    "DeliveryDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Date"])},
    "EstimateDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimate delivery"])},
    "NA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
    "Warranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warranty"])},
    "YearWarranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month Seller Warranty"])},
    "WarrantyNotAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warranty Not Available"])},
    "ShipFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ship From"])},
    "Overseas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overseas"])},
    "Voucher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vouchers"])},
    "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
    "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
    "VisitStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit store"])},
    "AddToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add To Cart"])},
    "BuyNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy Now"])},
    "ProductSpecifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Specifications"])},
    "ChatNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat Now"])},
    "SoldOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["售罄"])}
  },
  "ValidationMsg": {
    "FieldRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必填的"])},
    "SelectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选国家"])},
    "ValidNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入有效的手机号"])},
    "EnterPw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入密码"])},
    "PWRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码不能包含空格"])},
    "OTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入验证码"])},
    "ValiedCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证码无效"])},
    "Passwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入确认密码"])},
    "PwNotMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码不一致"])},
    "EnterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入邮箱"])},
    "ValidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入正确的邮箱账号"])},
    "MobNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入手机号"])},
    "ValidNickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入昵称"])},
    "PwTips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码必须同时包含大写、小写、数字和特殊字符其中三项且至少8位"])},
    "AddrTips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最多500字"])}
  },
  "NotItemsMessage": {
    "notItemcart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的购物车竟然是空的"])},
    "notItemTopay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您还没有相关的订单"])},
    "notNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有任何通知"])},
    "notItemToDeliver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您还没有相关的订单"])},
    "notSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对不起您搜索内容为空"])},
    "notFavourit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您喜欢的产品可以点击小红心添加到收藏里"])},
    "notItemToReceive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您还没有相关的订单"])},
    "notCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您还没有相关的订单"])},
    "notFootprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["足迹是空的 !"])},
    "notItemRefund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您还没有相关的订单"])},
    "notReviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["抱歉，该产品还没有评论"])}
  },
  "CancelOrderReasons": {
    "Duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重复订单"])},
    "WrongProd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品规格选错了"])},
    "ChangeAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收货地址选错了"])},
    "ChangeMind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不想要了"])},
    "FoundCheaper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价格贵"])},
    "deliveryTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送货时间太长了"])}
  },
  "invitationInfo": {
    "UnlockRewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请好友获取奖励"])},
    "ScantoRefer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分享二维码邀请好友!🎉"])},
    "MyInvitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的邀请"])},
    "Invited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请"])},
    "YourRank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的排名"])},
    "confInvitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已购买"])},
    "Leaderboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排行榜"])},
    "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排名"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["昵称"])},
    "Refferel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已购买"])}
  },
  "voucherInfo": {
    "CappedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["封顶"])},
    "PercentageDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["％ OFF"])},
    "FreeShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包邮"])},
    "VoucherValidDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有效"])},
    "VoucherApply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用"])},
    "VoucherApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已使"])},
    "VoucherCollect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["领取"])},
    "VoucherCollected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已领取"])},
    "VoucherCollectedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["领取成功"])},
    "VoucherCollectError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["领取失败"])},
    "AllVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平台"])},
    "ProductVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品"])},
    "ShopVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["店铺"])},
    "MallVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iCan Mall"])},
    "MyVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的优惠券"])},
    "RecommendedVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推荐"])},
    "ViewAllVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更多的"])},
    "ShopVoucherTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["优惠券和折扣"])}
  },
  "campain": {
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["天"])},
    "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小时"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分钟"])},
    "sec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["秒"])}
  },
  "GroupBuy": {
    "groupBuy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["团购"])},
    "NeedParticipant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["还差 "])},
    "NoParticipant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人可以团购"])},
    "timeOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["团购失败"])},
    "Invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分享"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["天"])},
    "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小时"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分钟"])},
    "sec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["秒"])},
    "InPeogress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["执行中"])},
    "Upcomming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["马上开始"])},
    "Completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已完成"])}
  },
  "AddressMobile": {
    "alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手机号码"])},
    "enterAlt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["备用号码"])},
    "area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["区域"])},
    "selectArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择省、区、市"])},
    "yourArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择您的区域"])},
    "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭"])},
    "Country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["country"])},
    "Province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["province"])},
    "District": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["district"])},
    "Town": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["town"])}
  },
  "Languages": {
    "English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英语"])},
    "Chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中国人"])},
    "Turkish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["土耳其"])},
    "Russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["俄语"])},
    "Portugal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["葡萄牙"])}
  },
  "BannerText": {
    "BannerBottomHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["快捷方便的购物体验！"])},
    "BannerBottomSub1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即下载我们的应用程序，探索电子产品、时尚体验、惊喜优惠等等！"])},
    "BannerBottomSub2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["扫描二维码下载iCan商城"])}
  },
  "OrderDetails": {
    "OrderDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单详情"])},
    "CourierService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["快递公司"])},
    "TrackingNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["快递单号"])},
    "Receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发票"])},
    "OrderNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单号"])},
    "Copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拷贝"])},
    "PlacedOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建时间 "])},
    "PaidOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付时间"])},
    "Remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["备注"])},
    "Insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["快递保险"])},
    "ShopDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["店铺优惠"])},
    "SeeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更多"])},
    "SeeLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "SubmitOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交订单"])},
    "Paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已支付"])},
    "Shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已发货"])},
    "Delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已收货"])},
    "Copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已复制到剪贴板"])}
  },
  "ContactUs": {
    "ContactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系下我们"])},
    "NeedHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需要帮忙?"])},
    "ContactEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以联系iCan商城."])},
    "OfficeAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["办公地址"])},
    "SocialMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关注我们"])}
  },
  "wishList": {
    "GroceryList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["杂货清单"])},
    "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])},
    "CreateList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建购物清单"])},
    "TotalPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总金额"])},
    "NothingGrocery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你还没有任何购物清单"])},
    "NothingGroceryMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建您的购物清单"])},
    "CreateNewBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建新列表"])},
    "GrocryListLopic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新列表"])},
    "CreateListValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必填的"])},
    "CreateListBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建列表"])},
    "SelectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
    "AddItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加商品"])},
    "ItemListEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的列表是空的"])},
    "ItemEmptyMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首先将商品添加到您的购物清单里。"])},
    "AddingItemBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加商品"])},
    "Discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["折扣价"])},
    "SubTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小计"])},
    "Delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运费"])},
    "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
    "ShareList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分享列表"])},
    "BuyList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购买清单"])},
    "CreateGrecerySuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建清单成功"])},
    "FaildeGroceryError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建清单失败"])},
    "DeleteItemssuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除成功"])},
    "SelectedWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择商品"])},
    "Quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量"])},
    "Confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已购买"])},
    "ItemAddedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品添加成功"])},
    "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品"])},
    "Add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再买"])},
    "More": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立省 "])},
    "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["节省"])},
    "PlaceholederTextBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["列表名字"])}
  }
}
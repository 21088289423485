
export const setCookie = (cName, cValue, cExpiry = 7, cPath = '/', isSecure = true, isHttpOnly = false, sameSite = 'Lax') => {
    if (getCookie(cName) !== undefined) {
        console.warn(`Cookie with name '${cName}' already exists. Skipping setting a new one.`);
        return;
    }
    let date = new Date();
    date.setTime(date.getTime() + (cExpiry * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();

    let cookieString = `${cName}=${cValue}; ${expires}; path=${cPath}; SameSite=${sameSite}`;
    if (isSecure) cookieString += "; Secure";
    if (isHttpOnly) cookieString += "; HttpOnly";

    document.cookie = cookieString;
};

// Get a cookie by name
export const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return undefined; // Explicitly return undefined if cookie is not found
};

// Delete a specific cookie
export const deleteCookie = (cName, cPath = '/') => {
    document.cookie = `${cName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${cPath};`;
};

// Delete all cookies
export const deleteAllCookies = () => {
    const cookies = document.cookie.split(";");

    cookies.forEach((cookie) => {
        const [name] = cookie.trim().split("=");
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    });
};

import { defineStore } from 'pinia'

const store = {
    state: () => ({ 
        loginModal: false,
        index:"",
        downloadModal:false,
        ProductDetails:[],
        addWishListPopup:false,
    }),
    getters: {
        checkLoginModal(){
            return this.loginModal;
        },
        checkdownloadModal(){
           return this.downloadModal;
        },
        getProductDetails(state) {
            return state.ProductDetails;
        },
        checkAddWishListPopup(){
            return this.addWishListPopup;
        }
    },
    actions: {
        toggleLoginModal(){
            this.loginModal = !this.loginModal;
        },
        setPhoneCodeIndex(index){
            this.index = index
        },
        toggleDownloadModal(){
            this.downloadModal =!this.downloadMoadl;
        },
        setProductId(Product){
            this.ProductDetails = Product
            console.log(this.ProductDetails);
        },
        toggleaddWishListPopup(){
            this.addWishListPopup =! this.addWishListPopup
        },
    }
};

export const useLoginModalStore = defineStore("loginModalStore",store);
